@use 'sass:map';

.b360PrimaryText {
    color: var(--bis-primary-color) !important;
}

$font-weight: () !default;
$font-weight: map.merge(
    (
        100: 100,
        200: 200,
        300: 300,
        400: 400,
        500: 500,
        600: 600,
        700: 700,
        800: 800,
        900: 900,
    ),
    $font-weight
);

$font-sizes: () !default;
$font-sizes: map.merge(
    (
        8: 0.8rem,
        10: 1rem,
        12: 1.2rem,
        13: 1.3rem,
        14: 1.4rem,
        16: 1.6rem,
        18: 1.8rem,
        20: 2rem,
        22: 2.2rem,
        24: 2.4rem,
        26: 2.6rem,
        28: 2.8rem,
        30: 3rem,
        32: 3.2rem,
        48: 4.8rem,
        56: 5.6rem,
        84: 8.4rem,
        100: 10rem,
    ),
    $font-sizes
);

@each $weight, $value in $font-weight {
    .f-#{$weight} {
        font-weight: $value !important;
    }
}

@each $size, $value in $font-sizes {
    .f-#{$size} {
        font-size: $value !important;
    }
}

// icons font-size is for icon size
@each $size, $value in $font-sizes {
    .is#{$size} {
        font-size: $value !important;
        width: $value !important;
        height: $value !important;
    }
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    src: url('/assets/fonts/Rubik-SemiBoldItalic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    src: url('/assets/fonts/Rubik-Black.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 900;
    src: url('/assets/fonts/Rubik-BlackItalic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/Rubik-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/Rubik-BoldItalic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 800;
    src: url('/assets/fonts/Rubik-ExtraBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 800;
    src: url('/assets/fonts/Rubik-ExtraBoldItalic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/Rubik-Italic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/Rubik-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/Rubik-LightItalic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/Rubik-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    src: url('/assets/fonts/Rubik-MediumItalic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Rubik-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/Rubik-SemiBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'ZapfDingbats';
    src: url('/assets/fonts/Zapf-Dingbats-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
