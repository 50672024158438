%GRAY_STATUS_STYLING {
    color: var(--bis-gray-status-text-color) !important;
    background-color: var(--bis-gray-status-bg-color) !important;
    border: var(--bis-gray-status-border);
}

%PURPLE_STATUS_STYLING {
    color: var(--bis-purple-status-text-color) !important;
    background-color: var(--bis-purple-status-bg-color) !important;
    border: var(--bis-purple-status-border);
}

%GREEN_STATUS_STYLING {
    color: var(--bis-green-status-text-color) !important;
    background-color: var(--bis-green-status-bg-color) !important;
    border: var(--bis-green-status-border);
}

%DARK_STATUS_STYLING {
    color: var(--bis-dark-status-text-color) !important;
    background-color: var(--bis-dark-status-bg-color) !important;
    border: var(--bis-dark-status-border);
}

%LIGHT_GREEN_STATUS_STYLING {
    color: var(--bis-light-green-status-text-color) !important;
    background-color: var(--bis-light-green-status-bg-color) !important;
    border: var(--bis-light-green-status-border);
}

%ORANGE_STATUS_STYLING {
    color: var(--bis-orange-status-text-color) !important;
    background-color: var(--bis-orange-status-bg-color) !important;
    border: var(--bis-orange-status-border);
}

%RED_STATUS_STYLING {
    color: var(--bis-red-status-text-color) !important;
    background-color: var(--bis-red-status-bg-color) !important;
    border: var(--bis-red-status-border);
}

%BLUE_STATUS_STYLING {
    color: var(--bis-blue-status-text-color) !important;
    background-color: var(--bis-blue-status-bg-color) !important;
    border: var(--bis-blue-status-border);
}

%LIGHT_ORANGE_STATUS_STYLING {
    color: var(--bis-light-orange-status-text-color) !important;
    background-color: var(--bis-light-orange-status-bg-color) !important;
    border: var(--bis-light-orange-status-border);
}

%LIGHT_BLUE_STATUS_STYLING {
    color: var(--bis-light-blue-status-text-color) !important;
    background-color: var(--bis-light-blue-status-bg-color) !important;
    border: var(--bis-light-blue-status-border);
}

.statusRow {
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    min-width: 90px;
    font-weight: 600;
    cursor: pointer;
}

span.b360StatusRow {
    margin: 0;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    line-height: 25px !important;
    transition: filter 0.1s ease; /* Smooth animation for hover */

    /* Hover effect in dark mode */

    body.dark_mode & {
        &:hover {
            filter: brightness(5); /* Brighten slightly */
        }
    }

    /* Hover effect in light mode */

    body.light_mode & {
        &:hover {
            filter: contrast(20); /* Darken slightly */
        }
    }
}

.IDLE,
.SKIPPED_BY_EMPLOYEE,
.idle {
    @extend %GRAY_STATUS_STYLING;
}

.EMPLOYER_PENDING,
.AWAITING_YOU,
.SENT_TO_EMPLOYEE {
    @extend %PURPLE_STATUS_STYLING;
}

.UPDATED_IN_SALARY,
.updated_in_salary {
    @extend %LIGHT_BLUE_STATUS_STYLING;
}

.APPROVED,
.DONE,
.ACTIVE,
.VIEWED_BY_EMPLOYEE {
    @extend %LIGHT_GREEN_STATUS_STYLING;
}

.EMPLOYEE_PENDING {
    @extend %DARK_STATUS_STYLING;
}

.RESUBMIT,
.REMINDER_SENT,
.EMPLOYEE_NOT_FOUND {
    @extend %ORANGE_STATUS_STYLING;
}

.STUCK,
.EMPLOYER_REJECTED,
.EMPLOYEE_DECLINED,
.EMPLOYEE_REJECTED,
.AWAITING_MANAGER_FILL,
.DELETED_BY_EMPLOYER,
.HIDDEN_FROM_EMPLOYEE,
.FINISHED_WITH_ERRORS {
    @extend %RED_STATUS_STYLING;
}

.EMPLOYEE_DONE {
    @extend %LIGHT_ORANGE_STATUS_STYLING;
}

.IN_PROCESS,
.AWAITING_MANAGER_APPROVAL,
.ONBOARDING,
.FILE_IN_PROCESS {
    @extend %BLUE_STATUS_STYLING;
}

.statusDot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-inline-end: 5px;
}

button.mat-mdc-unelevated-button.b360flatActionStatusBtn {
    margin: 0;
    padding: 0;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    min-width: 115px;
    font-weight: 600;
    line-height: 20px;

    &.IDLE,
    &.SKIPPED_BY_EMPLOYEE,
    &.idle {
        @extend %GRAY_STATUS_STYLING;
    }

    &.EMPLOYER_PENDING,
    &.AWAITING_YOU,
    &.SENT_TO_EMPLOYEE {
        @extend %PURPLE_STATUS_STYLING;
    }

    &.EMPLOYEE_DONE {
        @extend %LIGHT_ORANGE_STATUS_STYLING;
    }

    &.UPDATED_IN_SALARY,
    &.updated_in_salary {
        @extend %LIGHT_BLUE_STATUS_STYLING;
    }

    &.APPROVED,
    &.DONE,
    &.VIEWED_BY_EMPLOYEE {
        @extend %LIGHT_GREEN_STATUS_STYLING;
    }

    &.EMPLOYEE_PENDING {
        @extend %DARK_STATUS_STYLING;
    }

    &.RESUBMIT,
    &.REMINDER_SENT,
    &.EMPLOYEE_NOT_FOUND {
        @extend %ORANGE_STATUS_STYLING;
    }

    &.STUCK,
    &.EMPLOYER_REJECTED,
    &.EMPLOYEE_DECLINED,
    &.DELETED_BY_EMPLOYER,
    &.EMPLOYEE_REJECTED,
    &.AWAITING_MANAGER_FILL,
    &.FINISHED_WITH_ERRORS {
        @extend %RED_STATUS_STYLING;
    }

    &.IN_PROCESS,
    &.AWAITING_MANAGER_APPROVAL,
    &.FILE_IN_PROCESS {
        @extend %BLUE_STATUS_STYLING;
    }
}
