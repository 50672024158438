@use 'swiper_base';

swiper-container {
    width: 100%;
    height: 100%;
}

swiper-slide {
    background-color: var(--bis-card-background);
    max-height: 1100px;
}

.b360SwiperGlobalNavBtns {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 2rem);
    padding: 0 1rem;
    bottom: 1rem;
    z-index: 10;
    right: 0;
    left: 0;
    cursor: pointer;
}

.b360OnboardingSlide {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    img {
        align-self: center;
    }
}
.sildeImg {
    height: 100%;
    width: auto;
    max-height: 80px;
}

/* Custom styles for navigation buttons */
.custom-swiper-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    cursor: pointer;
    /* Additional custom styling */
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Positioning for previous and next buttons */
.swiper-button-prev-custom {
    left: 10px;
}

.swiper-button-next-custom {
    right: 10px;
}

/** desktop style => mobile first design */
@media screen and (min-width: 756px) {
    swiper-container {
        max-width: 640px;
        border-radius: var(--bis-primary-border-radius);
    }

    swiper-slide {
        height: 100%;
        border-radius: var(--bis-primary-border-radius);
    }
    .sildeImg {
        height: 100%;
        width: auto;
        max-height: 200px;
    }
}
