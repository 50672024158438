.fx-row {
    display: flex;
    flex-direction: row;
    &-center {
        @extend .fx-row;
        justify-content: center;
        align-items: center;
    }
}

.fx-sb {
    justify-content: space-between;
}

.fx-se {
    justify-content: space-evenly;
}
.fx-sa {
    justify-content: space-around;
}

.fx-grow {
    flex-grow: 1;
}
.fx-ac {
    align-items: center;
}
.fx-asc {
    align-self: center;
}
.fx-as {
    align-items: start;
}
.fx-ae {
    align-items: end;
}

.fx-jc {
    justify-content: center;
}

.fx-je {
    justify-content: flex-end;
}

.fx-col {
    display: flex !important;
    flex-direction: column;
    &-center {
        @extend .fx-col;
        justify-content: center;
        align-items: center;
    }
}

.fx-gap {
    gap: 1rem;
}
