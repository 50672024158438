.b360SwiperBase {
    position: fixed;
    background-color: var(--bis-backdrop);
    width: 100vw;
    height: 100%;
    z-index: 300;
    top: 0;
    right: 0;

    .backToEmp {
        position: fixed;
        left: 10px;
        top: 10px;
        z-index: 9999;
    }

    swiper-slide {
        overflow-y: auto;
    }

    /* Custom styles for navigation buttons */
    .custom-swiper-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1000;
        cursor: pointer;
        /* Additional custom styling */
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* Positioning for previous and next buttons */
    .swiper-button-prev-custom {
        left: 10px;
    }

    .swiper-button-next-custom {
        right: 10px;
    }

    // // desktop style => mobile first design
    @media screen and (min-width: 756px) {
        padding: 2rem 1rem;
        width: calc(100% - 2rem);
        height: calc(100% - 4rem);
        z-index: 300;
        top: 0;
        max-height: calc(100vh - 2rem);
    }
}
