.pac-container:after {
    content: none !important;
}

// all items under the pac items color black
.pac-item {
    * {
        color: black !important;
    }
}

.pac-matched {
    color: black !important;
}
