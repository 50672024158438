@use 'variable.scss' as vars;

// ALL DEVICES
.componentGrid {
    display: grid !important;
    grid-template-columns: repeat(20, minmax(0, 1fr));
    grid-column-gap: 10px;
    grid-template-rows: auto;
    margin: 0 1rem;
}
.innerGrid {
    display: grid !important;
    grid-template-columns: repeat(20, minmax(0, 1fr));
    grid-column-gap: 10px;
    grid-template-rows: auto;
}

.fullspan {
    grid-column: 1/ -1;
}

// ROW START
@each $number, $value in vars.$numbers {
    .rs#{$number} {
        grid-row-start: $value;
    }
}
// ROW END
@each $number, $value in vars.$numbers {
    .re#{$number} {
        grid-row-end: $value;
    }
}
// COL START
@each $number, $value in vars.$numbers {
    .cs#{$number} {
        grid-column-start: $value;
    }
}
// COL END
@each $number, $value in vars.$numbers {
    .ce#{$number} {
        grid-column-end: $value;
    }
}

// DESKTOP

@each $number, $value in vars.$numbers {
    .gr-span#{$number} {
        grid-column: span $value !important;
    }
}

@each $number, $value in vars.$numbers {
    .grid#{$number} {
        display: grid !important;
        grid-template-columns: repeat($value, minmax(0, 1fr)) !important;
        grid-template-rows: auto;
    }
}

@each $gridCol, $value in vars.$grid-cols {
    @each $gap, $number in vars.$numbers {
        .grid#{$gridCol}g#{$gap} {
            display: grid !important;
            grid-template-columns: repeat($value, minmax(0, 1fr));
            grid-column-gap: $number + 0px;
            grid-row-gap: 5px;
            grid-template-rows: auto;
        }
    }
}

.employeeMainGrid {
    display: grid !important;
    grid-template-columns: 1fr 680px 1fr 30px;
    grid-template-rows: var(--mat-toolbar-standard-height) auto;
}

// MOBILE
@media screen and (max-width: 1024px) {
    .componentGrid {
        padding-bottom: 60px;
    }

    @each $number, $value in vars.$grid-span-cells {
        // .gr-span#{$number} {
        //     grid-column: 1 / -1 !important;
        // }
    }

    @each $number, $value in vars.$grid-span-cells {
        .gr-sm-#{$number} {
            grid-column: span $number !important;
        }
    }

    @each $gridCol, $value in vars.$grid-cols {
        @each $gap, $number in vars.$numbers {
            .gr-sm#{$gridCol}g#{$gap} {
                display: grid !important;
                grid-template-columns: repeat($value, minmax(0, 1fr));
                grid-column-gap: $number + 0px;
                grid-template-rows: auto;
            }
        }
    }

    // ROW START
    @each $number, $value in vars.$numbers {
        .rs-sm#{$number} {
            grid-row-start: $value;
        }
    }
    // ROW END
    @each $number, $value in vars.$numbers {
        .re-sm#{$number} {
            grid-row-end: $value;
        }
    }

    // COL START
    @each $number, $value in vars.$numbers {
        .cs-sm#{$number} {
            grid-column-start: $value;
        }
    }

    // COL END
    @each $number, $value in vars.$numbers {
        .ce-sm#{$number} {
            grid-column-end: $value;
        }
    }

    .employeeMainGrid {
        grid-template-columns: 100vw;
    }
}

.grid-sm14 {
    display: grid !important ;
    grid-template-columns: 1fr repeat(14, minmax(0, 1fr)) !important;
    grid-column-gap: 16px !important;
    grid-template-rows: auto;
}

.grid-sm16 {
    display: grid !important;
    grid-template-columns: repeat(16, minmax(0, 1fr)) !important;
    grid-column-gap: 6px !important;
    grid-template-rows: auto;
}

.businessMainGrid {
    display: grid !important;
    grid-template-columns: repeat(16, minmax(0, 1fr)) !important;
    grid-column-gap: 16px;

    grid-template-rows: var(--mat-toolbar-standard-height) auto;
}

// ONLY FOR BUSINESS SIDE TO BE DEPRECATED IN FUTURE
.gridmain,
.gridtopbar {
    display: grid !important;
    grid-template-columns: 85px 1fr repeat(10, minmax(50px, 105px)) 1fr 30px;
    grid-column-gap: 16px;
    grid-template-rows: 48px auto;
    header {
        overflow: hidden;
    }
    nav {
        overflow: hidden;
    }
    main {
        overflow-y: auto;
    }
}
