.mat-mdc-menu-content {
    padding: 0;

    box-sizing: border-box;

    .b360ContactDetails {
        max-width: 395px;
        width: 100%; /* Ensure it takes up the full width within its max-width */
        display: flex; /* Ensure it's a flex container */
        flex-direction: row; /* Ensure items are in a row */
        box-sizing: border-box;

        .avatarWrapper {
            flex: 0 0 auto;
        }

        .detailsWrapper {
            flex: 1 1 0;
            box-sizing: border-box; /* Includes padding in the width */
            // flex-wrap: wrap;
        }

        p,
        h3 {
            overflow-wrap: break-word; /* Modern browsers */
            word-wrap: break-word; /* Legacy support */
            hyphens: auto; /* Optional: Adds hyphens where appropriate */
            word-break: break-word; /* Ensures long words break */
            cursor: pointer;
            margin-bottom: 0;
            &:hover {
                color: var(--bis-primary-color) !important;
            }
        }
    }

    .employeeChipBtn {
        outline: unset;
        background-color: unset;
        border: unset;
        width: 100%;
        border-top: var(--bis-primary-border);
        border-radius: 0;
        bottom: 0;
        padding: 0.7rem;
        cursor: pointer;
        p {
            letter-spacing: 1.2px;
            &:hover {
                color: var(--bis-primary-color) !important;
            }
        }
    }
}
