// // @use './functions.scss';

@use 'sass:map';

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map.merge(
    (
        0: 0,
        1: $spacer * 0.25,

        2: $spacer * 0.5,

        3: $spacer,
        4: $spacer * 1.5,

        5: $spacer * 2.5,
    ),
    $spacers
);
@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {
        // Generic properties
        .#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }
        // Top
        .#{$abbrev}t-#{$size} {
            #{$prop}-top: $length !important;
        }
        // Bottom
        .#{$abbrev}b-#{$size} {
            #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $length !important;
            #{$prop}-right: $length !important;
        }

        .#{$abbrev}y-#{$size} {
            #{$prop}-top: $length !important;
            #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}r-#{$size} {
            #{$prop}-right: $length !important;
        }

        .#{$abbrev}l-#{$size} {
            #{$prop}-left: $length !important;
        }

        .#{$abbrev}s-#{$size} {
            #{$prop}-inline-start: $length !important;
        }

        .#{$abbrev}e-#{$size} {
            #{$prop}-inline-end: $length !important;
        }
    }
}

// Special margin utils
.m-auto {
    margin: auto !important;
}
