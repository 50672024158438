/* Define a mixin with the repeated code */
@mixin lightScrollbarStyles {
    /* Thumb */
    ::-webkit-scrollbar-thumb {
        background: rgb(94 94 94);
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background-color: #d9d9d9;
    }
}

@mixin darkScrollbarStyles {
    /* Thumb */
    ::-webkit-scrollbar-thumb {
        background: #7a7a7a;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background-color: white;
    }
}

html,
body {
    ::-webkit-scrollbar {
        display: none !important;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }
}

body.light_mode {
    @include lightScrollbarStyles;
}
@media (prefers-color-scheme: dark) {
    /* Handle */
    @include darkScrollbarStyles;
}

body.light_mode {
    @include lightScrollbarStyles;
}
@media (prefers-color-scheme: light) {
    /* Handle */
    @include lightScrollbarStyles;
}

@media screen and (max-width: 756px) {
    html,
    body {
        overflow-x: hidden;
        ::-webkit-scrollbar {
            display: block; /* Safari and Chrome */
        }
        -ms-overflow-style: block; /* Internet Explorer 10+ */
        scrollbar-width: block; /* Firefox */
    }
}
