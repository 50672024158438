image-cropper {
    padding: 0 !important;
    .ngx-ic-overlay {
        outline: none !important;
    }

    .ngx-ic-cropper.ngx-ic-round::after {
        box-shadow: unset;
    }
}
