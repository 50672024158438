@use 'sass:map';

:root {
    // OVERRIDES
    --bis-default-avatar-bg: #f38829;
    --bis-dialog-icon-bg: #212121;
    --bis-dialog-icon-color: white;
    --mat-toolbar-standard-height: 48px; // your desired height
    --mat-table-row-item-label-text-font: 'Rubik';
    --mat-table-header-headline-size: 12px;
    --bis-border-radius: 5px;
    --bis-btn-border-radius: 10px;

    --bis-primary-btn-color: rgba(253, 241, 234, 1);
    --bis-primary-btn-text-color: #ff8400;
    --swiper-pagination-color: var(--bis-primary-color);
    --swiper-pagination-bottom: 14px;

    --bis-shabbat-color: #b2ebe8;
    --bis-info-bg: #ffead1;

    --bis-disabled-background: #f6f6f6;

    --bis-form-editor-background: #f7f5f0;

    --bis-background-active-chat: #f0f2f5;

    --b360-table-grid-cols: 42;
    --bis-danger: #ff725c;
    --bis-background-hover-chat: #e2e2de;
    --bis-background-hover: #e2e2de;

    --bis-secondary-border: 1px solid #70707050;
    --bis-confirm-border: 1px solid #4a4a4a33;
    --bis-chat-border: 1px solid #70707033;
    --bis-cancel-border: 1px solid #fb9e3b;
    --bis-nav-background: #f6f6f6;
    --bis-text-bold: 600;
    --bis-chat-header: #fbe4ca;
    --bis-backdrop: #231f20bf;
    --bis-large-border-radius: 10px;
    --main-background: #fcf2e8;
    --bis-primary-hover-background: #efb06d59;
    --bis-primary-transition: background-color 0.1s ease-in-out;
    --bis-mini-header-background: #34d1bf;
    --bis-top-bar-height: 48px;
    --bis-calander-header-background: #fb9e3b10;
    --bis-calander-header-text: #231f20;
    --bis-primary-toolbar-background: white;

    --bis-dark-600: #747474;

    --bis-chat-text-light: #707070;
    --scrollbarBG: #ffffff;
    --whiteText: #f1f1f1;
    --orangeText: orange;
    --thumbBG: #707070;
    --bis-chat-border: 1px solid #e9edef;
    --bis-text-bolder: 800;
    --bis-text-light: 200;
    --bis-text-regular: 400;
    --bis-primary-border-radius: 5px;
    --bis-primary-margin-big: 2.5rem;
    --bis-primary-margin-medium: 1rem;
    --bis-primary-margin-small: 0.5rem;
    --bis-primary-margin-y: 2rem 0;
    --bis-primary-margin-x: 0 1rem;
    --bis-primary-margin-heading: 3.5rem 1rem;
    --bis-primary-padding-y: 3rem 0;
    --bis-chat-text-bar-background: #e6e6e6;
    --bis-chat-window-search-bar-background: #f4f4f4;
    --bis-calendar-total: #0566ef;
    --bis-primary-text: #000000;
    --bis-secondary-text: orange;
    --bis-calendar-primary: #78d0c7;
    --bis-calendar-secondary: #00af9e;
    --bis-approve-color: #0aac3b;
    --bis-orange-color: #fb9e3b;
    --b360-primary-outline: 1px solid #ca6f0d;
    --bis-decline-color: #ff4d31;
    --bis-primary-background: #fbfbfb;
    --bis-mini-border: 1px solid #7c7c7c45;
    --bis-primary-radius: 5px;
    --bis-grad-ver-rgb: rgb(239, 176, 109);
    --bis-elevation-z1: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);

    --bis-mini-shadow: 0px 0px 3px 0px #d4d1d1;
    --bis-shadow: 0px 3px 6px 0px #00000029;
    --bis-elevation-z2: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
    --bis-elevation-z3: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
    --bis-dark-box-shadow: 0px 3px 3px 0px rgb(74 74 74 / 42%);

    --bis-grad: linear-gradient(
        180deg,
        rgba(239, 176, 109, 1) 0%,
        rgba(247, 153, 129, 1) 50%,
        rgba(255, 130, 150, 1) 100%
    );
    --bis-grad-ver: linear-gradient(
        270deg,
        rgba(239, 176, 109, 1) 0%,
        rgba(247, 153, 129, 1) 50%,
        rgba(255, 130, 150, 1) 100%
    );

    --bis-contrast-text: #ffffff;
    --bis-top-bar-background: #fff;
    --font-family-apple: system-ui, -apple-system, BlinkMacSystemFont,
        '.SFNSText-Regular', sans-serif;
    --font-family-code: ui-monospace, Menlo, Consolas, Monaco, monospace;
    --font-family-default: Helvetica, Arial, sans-serif;

    --bis-primary-color: #e77801;

    --bis-contrast-backdrop: #ffffffd8;

    --bis-accent-background: #252525;
    --bis-primary-hover-background: #e9e9e9;
}

body.dark_mode {
    --bis-info-bg: #0077a7;
    --bis-dialog-icon-bg: white;
    --bis-dialog-icon-color: #212121;
    --bis-accent-background: #e9e9e9;
    --bis-active-drop-zone: #ffffff24;

    --bis-main-text-color: #ffffff;
    --bis-table-link-active-background: #424242;
    --bis-inputlike-background: #575757;
    --bis-active-link: #e77801;
    --bis-primary-border: 1px solid #ffffff47;
    --bis-primary-toolbar-background: #424242;
    --bis-primary-background: #1a1a1a;
    --bis-table-picker-tab-unactive-bg: transparent;
    --bis-card-background: #424242;
    --bis-form-editor-background: #393939;
    --bis-card-border: lightgray 1px solid;

    --bis-f101-box-shadow: 0px 0px 10px 2px #00000082;
    --bis-f101-card-border: #414141 1px solid;
    --bis-label-color: #dfdfdf;
    --bis-primary-text-color: #ffffff;
    --bis-active-link-bg-color: #595959;

    --bis-file-selected-border: 2px dashed #a5ffc2;
    --bis-file-selected-bg: #003d2e;

    --bis-shabbat-color: #0d736d;

    --bis-contrast-backdrop: #161515d8;

    --bis-disabled-background: #393838;

    --bis-primary-hover-background: #5c5d5d;

    --bis-cancel-btn-color: #949494;
    --bis-primary-btn-color: #ff750c;

    --bis-bottom-navbar-logo-color: rgba(250, 247, 244, 0.6392156863);

    .mat-toolbar.mat-accent {
        --mat-toolbar-container-background-color: #292929;
    }

    --mdc-filled-button-label-text-color: black;

    // status
    // GRAY
    --bis-gray-status-text-color: #707070;
    --bis-gray-status-bg-color: #524e4910;
    --bis-gray-status-border: 1px solid #7070701f;
    // PURPLE
    --bis-purple-status-text-color: #ffffff;
    --bis-purple-status-bg-color: #6665dd;
    --bis-purple-status-border: 1px solid #6665dd1f;
    // GREEN
    --bis-green-status-text-color: #ffffff;
    --bis-green-status-bg-color: rgb(14 93 0);
    --bis-green-status-border: 1px solid rgb(34 107 6);
    // DARK
    --bis-dark-status-text-color: #232323;
    --bis-dark-status-bg-color: #524e4910;
    --bis-dark-status-border: 1px solid #fffdfa1f;
    // LIGHT GREEN
    --bis-light-green-status-text-color: #ffffff;
    --bis-light-green-status-bg-color: #1c6d3ce8;
    --bis-light-green-status-border: 1px solid #1c6d3ce8;
    // LIGHT BLUE
    --bis-light-blue-status-text-color: #ffffff;
    --bis-light-blue-status-bg-color: #2392f5e8;
    --bis-light-blue-status-border: 1px solid #2392f5e8;
    // ORANGE
    --bis-orange-status-text-color: #ff715b;
    --bis-orange-status-bg-color: #ff715b10;
    --bis-orange-status-border: 1px solid #ff715b1f;
    // RED
    --bis-red-status-text-color: #ffffff;
    --bis-red-status-bg-color: #951a1a;
    --bis-red-status-border: 1px solid #951a1a;
    // BLUE
    --bis-blue-status-text-color: #ffffff;
    --bis-blue-status-bg-color: #067193;
    --bis-blue-status-border: 1px solid #0671931f;
    // LIGHT ORANGE
    --bis-light-orange-status-text-color: #ffffff;
    --bis-light-orange-status-bg-color: #fb9e3b4f;
    --bis-light-orange-status-border: 1px solid #fb9e3b4f;

    --bis-dark-status-text-color: #ffffff;
}

body.light_mode {
    --bis-main-text-color: #000000;
    --bis-inputlike-background: #f1f1f1;
    --mat-table-header-headline-color: rgba(0, 0, 0, 0.65);
    --bis-border-only-light-mode: lightgray 1px solid;
    --bis-table-link-active-background: #feead5;
    --bis-active-link: #efb06d;
    --bis-primary-border: 1px solid #70707021;
    --bis-primary-background: rgba(250, 247, 244, 1);
    --bis-table-picker-tab-unactive-bg: #ffffff;
    --bis-card-background: #ffffff;
    --bis-card-border: lightgray 1px solid;
    --bis-f101-card-border: lightgray 1px solid;
    --bis-label-color: #686868;
    --bis-primary-text-color: #000000;
    --bis-cancel-btn-color: #e0e6ea;
    --bis-approve-btn-color: #fc9647;
    --bis-secondary-box-shadow: 0px 2px 5px #00000018;
    --bis-box-shadow: 0px 4px 6px 0px #d6d6d6;
    --bis-f101-box-shadow: 0px 4px 6px 0px #d6d6d6;
    --bis-active-link-bg-color: #dddddd;
    --bis-active-drop-zone: #0000001c;
    --bis-bottom-navbar-logo-color: rgba(250, 247, 244, 0.6392156863);

    --bis-file-selected-border: 2px dashed black;
    --bis-file-selected-bg: #00eb9c;

    // status
    // GRAY
    --bis-gray-status-text-color: #707070;
    --bis-gray-status-bg-color: #524e4910;
    --bis-gray-status-border: 1px solid #7070701f;
    // PURPLE
    --bis-purple-status-text-color: #6665dd;
    --bis-purple-status-bg-color: #5e5bff45;
    --bis-purple-status-border: 1px solid #6665dd1f;
    // GREEN
    --bis-green-status-text-color: #ffffff;
    --bis-green-status-bg-color: rgb(14 93 0);
    --bis-green-status-border: 1px solid rgb(34 107 6);
    // DARK
    --bis-dark-status-text-color: #2e2d2d;
    --bis-dark-status-bg-color: #524e4910;
    --bis-dark-status-border: 1px solid #2e2d2d1f;
    // LIGHT GREEN
    --bis-light-green-status-text-color: #005d25;
    --bis-light-green-status-bg-color: #00933b35;
    --bis-light-green-status-border: 1px solid #00933b1f;
    // LIGHT BLUE
    --bis-light-blue-status-text-color: #ffffff;
    --bis-light-blue-status-bg-color: #a4cff5e8;
    --bis-light-blue-status-border: 1px solid #a4cff5e8;
    // ORANGE
    --bis-orange-status-text-color: #ff715b;
    --bis-orange-status-bg-color: #ff715b10;
    --bis-orange-status-border: 1px solid #ff715b1f;
    // RED
    --bis-red-status-text-color: #930000;
    --bis-red-status-bg-color: #ff070712;
    --bis-red-status-border: 1px solid #9300001f;
    // BLUE
    --bis-blue-status-text-color: #067193;
    --bis-blue-status-bg-color: #daedff;
    --bis-blue-status-border: 1px solid #0671931f;
    // LIGHT ORANGE
    --bis-light-orange-status-text-color: #fb9e3b;
    --bis-light-orange-status-bg-color: #fb9e3b10;
    --bis-light-orange-status-border: 1px solid #fb9e3b1f;
}

/*Theme Colors*/

$themecolor: #4798e8;
$themecolor-dark: #028ee1;
$themecolor-alt: #26c6da;
$theme-light: #fff;
$theme-dark: #212529;

$top-bar-height: 4.8rem !default;

$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);

$white: #fff !default;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #a1aab2 !default;
$gray-600: #6c757d !default;
$gray-700: #4f5467 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$blue: #4798e8 !default;
$indigo: #6610f2 !default;
$purple: #7460ee !default;
$pink: #e83e8c !default;
$red: #ef6e6e !default;
$light-info: #e6f2fa !default;
$light-danger: #fae6e6 !default;
$light-success: #e9f9f7 !default;
$light-warning: #f8efd7 !default;
$orange: #ff6d00 !default;
$yellow: #ffbc34 !default;
$green: #0aac3b !default;
$teal: #20c997 !default;
$cyan: #00ae9e !default;
$primary-muted: rgba(255, 191, 95, 0.446) !default;
$primary: #fb9e3b !default;
$text-muted: $gray-500 !default;
$colors: (
    blue: $blue,
    indigo: $indigo,
    purple: $purple,
    pink: $pink,
    red: $red,
    black: $black,
    orange: $orange,
    yellow: $yellow,
    green: $green,
    teal: $teal,
    cyan: $cyan,
    white: $white,
    gray: $gray-600,
    gray-dark: $gray-800,
    light-info: $light-info,
    light-danger: $light-danger,
    light-success: $light-success,
    light-warning: $light-warning,
);
$primary: $primary !default;
$secondary: $cyan !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$cyan: $cyan !default;
$orange: $orange !default;
$text-theme-colors: () !default;
$text-theme-colors: map.merge(
    (
        'primary': $black,
        'light': $white,
        'orange': $primary,
        'red': $red,
        'black': $black,
        'white': $white,
        'green': $green,
        'purple': $purple,
        'secondery': $gray-700,
        'muted': $gray-500,
        'success': $success,
    ),
    $text-theme-colors
);

$theme-colors: () !default;
$theme-colors: map.merge(
    (
        'primary': $primary,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'dark': $dark,
        'cyan': $cyan,
        'orange': $orange,
        'purple': $purple,
        'light-info': $light-info,
        'light-danger': $light-danger,
        'light-success': $light-success,
        'light-warning': $light-warning,
    ),
    $theme-colors
);

// Components
$component-active-color: $white !default;
$component-active-bg: $themecolor !default;
$badge-pill-padding-x: 0.2em !default;
$badge-pill-padding-y: 1em !default;

// Define common padding and border radius sizes and more.
$border-width: 1px !default;
$border-color: $gray-200 !default;
$border-radius: 5px !default;
$border-radius-lg: 15px !default;
$border-radius-sm: 5px !default;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1600px,
) !default;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map.merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 2.5,
        ),
    ),
    $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map.merge(
    (
        17: 17%,
        25: 25%,
        50: 50%,
        75: 75%,
        80: 80%,
        90: 90%,
        100: 100%,
        auto: auto,
    ),
    $sizes
);
// stylelint-enable

$font-sizes: () !default;
$font-sizes: map.merge(
    (
        8: 0.8rem,
        10: 1rem,
        12: 1.2rem,
        13: 1.3rem,
        14: 1.4rem,
        16: 1.6rem,
        18: 1.8rem,
        20: 2rem,
        22: 2.2rem,
        24: 2.4rem,
        26: 2.6rem,
        28: 2.8rem,
        30: 3rem,
        32: 3.2rem,
        48: 4.8rem,
        56: 5.6rem,
        84: 8.4rem,
        100: 10rem,
    ),
    $font-sizes
);

$font-align: () !default;
$font-align: map.merge(
    (
        c: center,
        r: right,
        l: left,
        j: justify,
    ),
    $font-align
);

$grid-span-cells: () !default;
$grid-span-cells: map.merge(
    (
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        10: 10,
        11: 11,
        12: 12,
        14: 14,
        15: 15,
        16: 16,
        18: 18,
        20: 20,
        22: 22,
        24: 24,
        31: 31,
        30: 30,
    ),
    $grid-span-cells
);

$numbers: (
    0: -1,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 18,
    19: 19,
    20: 20,
    21: 21,
    22: 22,
    23: 23,
    24: 24,
    25: 25,
    26: 26,
    27: 27,
    28: 28,
    29: 29,
    30: 30,
    31: 31,
    32: 32,
);

$grid-cols: (
    2: 2,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    18: 18,
    20: 20,
    21: 21,
    22: 22,
    23: 23,
    24: 24,
    26: 26,
    27: 27,
    28: 28,
    30: 30,
    32: 32,
    34: 34,
    36: 36,
);

$scale-vals: (
    25: 0.25,
    50: 0.5,
    75: 0.75,
    100: 1,
    125: 1.25,
    150: 1.5,
    175: 1.75,
    200: 2,
);

$rotate-vals: (
    0: 0deg,
    90: 90deg,
    180: 180deg,
    270: 270deg,
    360: 360deg,
);

$viewport: 100vh !default;
